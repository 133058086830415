// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-convo-js": () => import("./../../../src/pages/convo.js" /* webpackChunkName: "component---src-pages-convo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lessons-js": () => import("./../../../src/pages/lessons.js" /* webpackChunkName: "component---src-pages-lessons-js" */),
  "component---src-templates-lesson-js": () => import("./../../../src/templates/Lesson.js" /* webpackChunkName: "component---src-templates-lesson-js" */)
}

